import AuthService from '/src/services/auth.service.js';
import { capitalizeFirstLetter } from '@/utilites/helpers';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

initialState.checkoutState = JSON.parse(localStorage.getItem('checkoutState'));

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, userObject) {
      return AuthService.login(userObject).then(
        response => {
          commit('loginSuccess', response);
          return Promise.resolve(response);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    socialLogin({ commit }, userObject) {
      return AuthService.socialLogin(userObject).then(
        response => {
          commit('loginSuccess', response);
          return Promise.resolve(response);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    setLoginCheckoutState({ commit }, data) {
      commit('loginCheckoutState', data);
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, userObject) {
      return AuthService.register(userObject).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    loginSuccess(state) {
      state.status.loggedIn = true;
      state.user = JSON.parse(localStorage.getItem('user'));
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    setFirstName(state, firstName) {
      state.user.User.FirstName = firstName;
      localStorage.setItem('user', JSON.stringify(state.user));
    },
    setPaymentMethod(state, PaymentMethod) {
      state.user.User.PaymentMethod = PaymentMethod;
      localStorage.setItem('user', JSON.stringify(state.user));
    },

    setLastName(state, lastName) {
      state.user.User.LastName = lastName;
      localStorage.setItem('user', JSON.stringify(state.user));
    },
    setCustomerStripeId(state, CustomerStripeId) {
      state.user.User.CustomerStripeId = CustomerStripeId;
      localStorage.setItem('user', JSON.stringify(state.user));
    },
    loginCheckoutState(state, data) {
      initialState.checkoutState = localStorage.setItem('checkoutState', data);
      state.checkoutState = data;
    }
  },  
  getters: {
    getLoginUserStatus(state) {
      return state.status.loggedIn;
    },
    getGuestLoginUserStatus(state) {
      if(state.status.loggedIn) {
        return state.user.Type === "guest" ? 'guest' : null;
      }

      return null;
    },
    getLoginUserName(state) {
      if(state.user){
        return capitalizeFirstLetter(state.user.User.FirstName);
      }
    },
    getPaymentMethod(state) {
      if(state.user){
        return state.user.User.PaymentMethod;
      }
    },
    getUserFullName(state) {
      if(state.user){
        return capitalizeFirstLetter(state.user.User.FirstName) + " " + capitalizeFirstLetter(state.user.User.LastName);
      }
    },
     getStripeCustomerId(state) {
      if(state.user){
        return state.user.User.CustomerStripeId;
      }
    },
    getUserEmail(state) {
      if(state.user){
        return state.user.User.Email;
      }
    },
    
    getUserId(state) {
      if (state.user) {
        return state.user.CustomerGuid;
      }
    }
  }
};